import * as React from 'react'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import * as styles from '../styles/article.module.css'

interface Mdx {
	readonly title: string
	readonly body: string
}

interface SheetContentSection {
	readonly children: Mdx
}

const SheetContentSection = ({ children }: SheetContentSection) => (
	<div className='w-full bg-gray-800 flex flex-col text-gray-50'>
		<article className={styles.articleContainer}>
			<h1>{children.title}</h1>
			<div>
				<MDXRenderer>{children.body}</MDXRenderer>
			</div>
		</article>
	</div>
)

export default SheetContentSection
