import * as React from 'react'
import { getFeaturedSheets, PageSheet } from '../../repository/sheet.repository'
import { flow } from 'fp-ts/function'
import * as Array from 'fp-ts/Array'
import { constructNavigatableItem } from '../../common/navigatable'
import Layout from '../../components/layout'
import { getSiteData } from '../../repository/site.repository'
import { graphql } from 'gatsby'
import HeroHeader from '../../components/hero-header'
import Footer from '../../components/footer'
import SheetContentSection from '../../components/sheet-content-section'
import { Helmet } from 'react-helmet'

type QueryResult = {
	mdx: {
		body: string
		frontmatter: {
			img: string
			label: string
			description: string
		}
	}
}

interface SheetPageProps {
	readonly data: QueryResult
}

const SheetPage = ({ data }: SheetPageProps) => {
	const siteData = getSiteData()
	const sheets = getFeaturedSheets()

	return (
		<Layout
			img={data.mdx.frontmatter.img}
			siteUrl={siteData.url}
			title={siteData.title}
			subtitle={siteData.subtitle}
			navigationItems={[
				{ id: 'home', label: 'Home', url: '/' },
				...extractNavItems(sheets)
			]}
		>
			<HeroHeader
				title={data.mdx.frontmatter.label}
				subtitle=''
				description={data.mdx.frontmatter.description}
			/>
			<SheetContentSection>
				{{ title: data.mdx.frontmatter.label, body: data.mdx.body }}
			</SheetContentSection>
			<Footer />
			<Helmet>
				<title>
					{data.mdx.frontmatter.label} - {siteData.title}
				</title>
			</Helmet>
		</Layout>
	)
}

const extractNavItems = flow(
	Array.map((sheet: PageSheet) => ({
		id: sheet.id,
		label: sheet.label,
		url: `/sheet/${sheet.slug}`
	})),
	Array.map(constructNavigatableItem)
)

export const query = graphql`
	query ($id: String) {
		mdx(id: { eq: $id }) {
			frontmatter {
				img
				label
				description
			}
			body
		}
	}
`

export default SheetPage
